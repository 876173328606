import { Checkbox, Col, DatePicker, Form, Input, Row } from 'antd';
import B2becContactVia from 'components/B2becContactVia';
import B2becEnquiryModal from 'components/B2becEnquiryModal';
import B2becTranslation from 'components/B2becTranslation';
import B2BecLink from 'components/B2BLink';
import CustomButton from 'components/CustomButton';
import { notificationComponent } from 'components/Notification';
import { useAsync } from 'hooks';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { getFormatType } from 'libs/utils/formatDate';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fapiRentalService } from 'services/fapi/serviceRental';
import {
  selectCountryNames,
  selectRegions,
} from 'store/selectors/configSelector';
import { getCustomerAddress } from 'store/selectors/customerSelector';

import CustomAddressSelect from './CustomSelectAddress';

const { useForm } = Form;

const RentalModal = (props) => {
  const { children, deviceName } = props;
  const { t } = useTranslation();
  const [form] = useForm();

  const [visible, setVisible] = React.useState(false);

  const regions = useSelector(selectRegions);
  const customerAddress = useSelector(getCustomerAddress);
  const companyName = customerAddress?.name;

  const countryNamesConfig = useSelector(selectCountryNames);
  const countryName = useMemo(() => {
    if (!customerAddress) {
      return '';
    }

    const foundCountryName = countryNamesConfig.find(
      (countryNameConfig) => countryNameConfig.value === customerAddress.country
    );

    return foundCountryName ? foundCountryName.name : customerAddress.country;
  }, [countryNamesConfig, customerAddress]);

  const { execute, status } = useAsync(
    async (values) => {
      return fapiRentalService.rentalCreateRequest(values);
    },
    false,
    () => {
      setVisible(false);
      notificationComponent({
        type: NOTIFICATION_TYPE.SUCCESS,
        message: t('rental.successfullMessage'),
      });
      form.resetFields();
    }
  );

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();
    const body = {
      deviceName: values.deviceName,
      cleanObject: values.cleanObject,
      spaceMeasurement: values.spaceMeasurement,
      startDate: values.startDate.format(getFormatType().default),
      duration: values.duration,
      phoneNumber: values.contactPhone,
      email: values.contactEmail,
      furtherInformation: values.furtherInformation,
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      street: values.address.street,
      street4: values.address.street4,
      number: values.address.houseNumber || undefined,
      postCode: values.address.zip,
      region: values.address.region,
      country: values.address.country,
      city: values.address.city,
    };
    if (body.region && regions[body.region]) {
      body.region = regions[body.region].label;
    }
    execute(body);
  }, [execute, form, regions]);

  const isSubmitting = status === 'pending';

  return (
    <div>
      <div role="button" aria-hidden="true" onClick={() => setVisible(true)}>
        {children}
      </div>
      {visible && (
        <B2becEnquiryModal>
          <div className="enquiry-modal">
            <h3 className="enquiry-modal__header">{t('rental.modal.title')}</h3>
            <div className="scrollable">
              <p>{t('rental.modal.description')}</p>
              <Form
                layout="vertical"
                form={form}
                initialValues={{
                  companyName,
                  deviceName,
                  country: countryName,
                }}
                validateMessages={{
                  required: t('myProfile.form.validateMessages.required'),
                  types: {
                    email: t('enquiryModal.form.validateMesages.email'),
                    phone: t('enquiryModal.form.validateMesages.phone'),
                    number: t('enquiryModal.form.validateMesages.number'),
                  },
                }}
              >
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      label="Device"
                      className="custom-form-item"
                      name="deviceName"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('rental.modal.cleanObject')}
                      className="custom-form-item"
                      name="cleanObject"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('rental.modal.spaceMeasurement')}
                      className="custom-form-item"
                      name="spaceMeasurement"
                      rules={[
                        {
                          pattern: /^\d+$/,
                          message: t(
                            'enquiryModal.form.validateMesages.number'
                          ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('rental.modal.startDate')}
                      className="custom-form-item"
                      name="startDate"
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        showToday={false}
                        disabledDate={(current) =>
                          current && current.isBefore(moment(), 'day')
                        }
                        format={getFormatType().default}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('rental.modal.duration')}
                      className="custom-form-item"
                      name="duration"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t('rental.modal.furtherInformation')}
                      className="custom-form-item"
                      name="furtherInformation"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col>
                    <h4>
                      {t('service.serviceOverview.label.contactInformation')}
                    </h4>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="companyName"
                      label={t('enquiryModal.form.companyName.label')}
                      className="custom-form-item"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('form.label.contactPersonFirstName')}
                      className="custom-form-item"
                      name="firstName"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('form.label.contactPersonLastName')}
                      name="lastName"
                      className="custom-form-item"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t('rental.modal.locationMachine')}
                      className="custom-form-item"
                      name="address"
                      rules={[{ required: true }]}
                    >
                      <CustomAddressSelect />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t('enquiryModal.form.contactType.label')}
                      className="custom-form-item"
                      required
                    >
                      <B2becContactVia />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      className="custom-form-item"
                      name="term"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value === true) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              t('rental.modal.acceptTermsErrorMessage')
                            );
                          },
                        }),
                      ]}
                      valuePropName="checked"
                    >
                      <Checkbox>
                        <div
                          className="mandatory-title"
                          style={{ color: '#2b2b2b' }}
                        >
                          <B2becTranslation
                            value="rental.modal.acceptTerms"
                            isComponent
                            htmlTags={[
                              <B2BecLink
                                key="AGB"
                                to="/data-protection-policy"
                                target="_blank"
                              >
                                {/* Link */}
                              </B2BecLink>,
                            ]}
                          />
                        </div>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <B2becEnquiryModal.Footer>
              <CustomButton
                disabled={isSubmitting}
                onClick={() => setVisible(false)}
              >
                {t('enquiryModal.button.cancel')}
              </CustomButton>
              <CustomButton disabled={isSubmitting} onClick={handleSubmit}>
                {t('enquiryModal.button.submitForm')}
              </CustomButton>
            </B2becEnquiryModal.Footer>
          </div>
        </B2becEnquiryModal>
      )}
    </div>
  );
};

export default RentalModal;
