import './styles.scss';

import { CheckCircleFilled } from '@ant-design/icons';
import { Form, Modal } from 'antd';
import usePermission from 'hooks/usePermission';
import { MODULE_SERVICE_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fapiCustomerService } from 'services/fapi';

import CustomButton from '../../../components/CustomButton';
import useAsync from '../../../hooks/useAsync';
import { CONTACT_TYPES, EnquiryForm } from './components/EnquiryForm';

const { useForm } = Form;

const EnquiryModal = ({
  onClose,
  materialNumber,
  materialName,
  companyName,
  phoneNumber,
  email,
}) => {
  const { hasPermission: canCreateProductRequest } = usePermission(
    MODULE_SERVICE_RIGHTS.CREATE_PRODUCT_REQUEST
  );
  const { t } = useTranslation();
  const [form] = useForm();

  const submitProductEnquiry = (params) =>
    fapiCustomerService.submitProductEnquiry(params);

  const { execute, status, error } = useAsync(submitProductEnquiry, false);

  const isSuccess = status === 'success';

  const isLoading = status === 'pending';

  const shouldShowInput = !isSuccess;

  const handleRequestProductEnquiry = async ({
    contactType: type,
    ...params
  }) => {
    const body = { ...params, serviceContact: !!params.serviceContact };
    if (type === 'contactEmail') {
      delete body.contactPhone;
    } else if (type === 'contactPhone') {
      delete body.contactEmail;
    }
    execute(body);
  };

  const handleSubmit = async () => {
    try {
      if (canCreateProductRequest) {
        await form.validateFields();
        const values = form.getFieldsValue();

        const bodyRequest = {
          productNumber: materialNumber,
          productName: materialName,
          ...values,
        };

        handleRequestProductEnquiry(bodyRequest);
      }
    } catch (err) {
      // TODO handle exception
    }

    return {};
  };

  return (
    <Modal
      open
      className="enquiry-modal"
      closable={false}
      footer={null}
      width={570}
    >
      <h3 className="enquiry-modal__header">{t('enquiryModal.header')}</h3>
      {isSuccess ? (
        <div className="enquiry-modal-success">
          <CheckCircleFilled />
          <span className="enquiry-modal-success__message">
            {t('enquiryModal.successfullMessage')}
          </span>
          <CustomButton onClick={onClose}>
            {t('enquiryModal.button.backToDetails')}
          </CustomButton>
        </div>
      ) : null}
      {shouldShowInput ? (
        <>
          <div className="enquiry-modal__description">
            {t('enquiryModal.description')}
          </div>

          {error ? (
            <div className="enquiry-modal-error">
              {t('enquiryModal.errorMessage')}
            </div>
          ) : null}

          <EnquiryForm
            form={form}
            isLoading={isLoading}
            initialValues={{
              companyName,
              contactType: CONTACT_TYPES[0].value,
              contactEmail: email,
              contactPhone: phoneNumber,
            }}
          />

          <div className="enquiry-modal__footer">
            <CustomButton type="ghost" onClick={onClose} disabled={isLoading}>
              {t('enquiryModal.button.cancel')}
            </CustomButton>
            <CustomButton
              onClick={handleSubmit}
              disabled={!canCreateProductRequest}
              loading={isLoading}
            >
              {t('enquiryModal.button.pleaseContactme')}
            </CustomButton>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

EnquiryModal.defaultProps = {
  companyName: 'N/A',
  phoneNumber: '',
};

EnquiryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  materialNumber: PropTypes.string.isRequired,
  materialName: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  phoneNumber: PropTypes.string,
};

const useEnquiryModal = (props) => {
  const [visible, setVisible] = useState(false);

  const render = () =>
    visible ? (
      <EnquiryModal onClose={() => setVisible(false)} {...props} />
    ) : null;

  return {
    EnquiryModal: render,
    showEnquiryModal: () => setVisible(true),
  };
};

export default useEnquiryModal;
