import { useCountryName } from 'hooks';
import useRegion from 'hooks/useRegion';
import React from 'react';

import styles from './style.module.scss';

export const DeliveryAddress = (props) => {
  const { data, ignores = [] } = props;
  const { getAddressWithRegion } = useRegion();
  const { country } = data;
  const countryName = useCountryName(country);
  const { name, address, location, street4 } = getAddressWithRegion(
    data,
    ignores
  );

  return (
    <div className={styles.deliveryAddress}>
      <strong>{name}</strong>
      <span>{address}</span>
      <span>{street4}</span>
      <span>{location}</span>
      <strong>{countryName}</strong>
    </div>
  );
};
