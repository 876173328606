import './styles.scss';

import { Col, Row } from 'antd';
import B2becInfoLayout from 'components/B2becInfoLayout';
import { B2becButtonBack } from 'components/CustomButton/B2becButtonIcon';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { B2bUserInvitationForm } from '../../../components/B2bUserForm';

const errorTypeParse = (error) => {
  const response = error?.response;

  if (response?.status === 500) {
    const errorCode = response.data?.errorCode;
    return errorCode;
  }

  return undefined;
};

const InviteNewUserPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleBackToUserList = () => {
    history.replace(linkGenerator('/user-management'));
  };

  const handleError = (error, form) => {
    window.scrollTo({ top: 0 });
    const errorCode = errorTypeParse(error);

    if (errorCode === '409') {
      form.setFields([
        {
          name: 'contactEmail',
          errors: [<Trans key="" i18nKey="form.validate.emailInUse" />],
        },
      ]);
    }
  };

  const handleSuccess = () => {
    window.scrollTo({ top: 0 });
    handleBackToUserList();
  };

  return (
    <div className="invite-new-user">
      <span className="note">{t('form.note.mandatory')}</span>

      <div className="invite-new-user__information">
        <h2>{t('userManagement.userInformation.title')}</h2>
        <Row>
          <Col span={24} xl={12}>
            <B2bUserInvitationForm
              onSuccess={handleSuccess}
              onError={handleError}
              onCancel={handleBackToUserList}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

InviteNewUserPage.propTypes = {};

const InviteNewUserPageWithLayout = (props) => {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageId,
    pageType: PAGE_TYPES.USER_MANAGEMENT,
    pageName: 'invite new user',
  });

  const history = useHistory();

  const { t } = useTranslation();

  const onBackHandler = () => {
    history.push(linkGenerator('/user-management'));
  };

  return (
    <B2becInfoLayout>
      <B2becInfoLayout.Title title={t('pageTitle.inviteNewUser')}>
        <B2becButtonBack buttonSize="small" onClick={onBackHandler}>
          {t('buttonTexts.backToOverview')}
        </B2becButtonBack>
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        <InviteNewUserPage />
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
};

InviteNewUserPageWithLayout.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default InviteNewUserPageWithLayout;
