import './styles.scss';

import { Col, Divider, Row } from 'antd';
import { B2becButtonLink } from 'components/B2becButton';
import B2BecLink from 'components/B2BLink';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCountryNames } from 'store/selectors/configSelector';

const RequestServiceConfirmation = (props) => {
  const { data } = props;

  const {
    customerCompanyName,
    materialName,
    materialNumber,
    customerAddress,
    customerCity,
    customerZipCode,
    customerRegion,
    countryCode,
    caseType,
  } = data;

  const { t } = useTranslation();

  const countryNamesConfig = useSelector(selectCountryNames);

  const countryName = useMemo(() => {
    const foundCountryName = countryNamesConfig.find(
      (country) => country.value === countryCode
    );

    return foundCountryName.name || countryCode;
  }, [countryCode, countryNamesConfig]);

  const location = useMemo(() => {
    return [customerZipCode, customerRegion, customerCity].reduce(
      (acc, cur) => {
        if (cur) {
          return `${acc}, ${cur}`;
        }

        return acc;
      },
      ''
    );
  }, [customerZipCode, customerRegion, customerCity]);

  const st = React.useCallback(
    (key) => t(`service.confirmationPage.${key}`),
    [t]
  );

  return (
    <div className="service-confirmation">
      <h1 className="service-confirmation__header">{st('header')}</h1>
      <div className="service-confirmation__main">
        <div className="service-block">
          <div className="service-block__header">
            <h2 className="font-weight-bold">
              {st('serviceInformation.header')}
            </h2>
            <span>{st('serviceInformation.message')}</span>
          </div>
          <div className="service-block__content">
            <Row gutter={[20, 20]}>
              <Col span={24} md={12}>
                <div className="service-information">
                  <span className="font-weight-bold">
                    {st('serviceSummary.header')}
                  </span>
                  <span>{materialName}</span>
                  <span>{`${st(
                    'serviceSummary.itemNumber'
                  )}: ${materialNumber}`}</span>
                  <span>{`${st('serviceSummary.serviceType')}: ${st(
                    `serviceTypes.${caseType}`
                  )}`}</span>
                </div>
              </Col>
              <Col span={24} md={12}>
                <div className="service-information">
                  <span className="font-weight-bold">
                    {st('addressDate.header')}
                  </span>
                  <span>{`${st(
                    'addressDate.address'
                  )}: ${customerCompanyName}`}</span>
                  <span>{customerAddress}</span>
                  <span>{location}</span>
                  <span>{countryName}</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Divider />
        <div className="service-block service-confirmation__whatsnext">
          <div className="service-block__header service-block__header--center">
            <h2 className="font-weight-bold">{st('whatsnext')}</h2>
          </div>
          <div className="service-block__content">
            <Row gutter={[20, 20]}>
              <Col span={24} order={2} md={{ span: 12, order: 1 }}>
                <B2BecLink
                  component={B2becButtonLink}
                  ghost
                  block
                  to="/my-workspace"
                >
                  {st('button.backToWorkspace')}
                </B2BecLink>
              </Col>
              <Col span={24} order={1} md={{ span: 12, order: 2 }}>
                <B2BecLink
                  component={B2becButtonLink}
                  block
                  to="/my-machines"
                  style={{ height: '100%' }}
                >
                  {st('button.myMachines')}
                </B2BecLink>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestServiceConfirmation.defaultProps = {
  data: {
    customerCompanyName: '',
    materialName: '',
    materialNumber: '',
    customerAddress: '',
    customerCity: '',
    customerZipCode: '',
    countryCode: '',
    caseType: '',
  },
};

RequestServiceConfirmation.propTypes = {
  data: PropTypes.shape({
    customerCompanyName: PropTypes.string,
    materialName: PropTypes.string,
    materialNumber: PropTypes.string,
    customerAddress: PropTypes.string,
    customerCity: PropTypes.string,
    customerZipCode: PropTypes.string,
    countryCode: PropTypes.string,
    caseType: PropTypes.string,
  }),
};

export default RequestServiceConfirmation;
