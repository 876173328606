import { Form, Input } from 'antd';
import clsx from 'clsx';
import CustomButton from 'components/CustomButton';
import ImageWithFallBack from 'components/ImageWithFallback';
import { useAsync } from 'hooks';
import useSessionBased from 'hooks/useSessionBased';
import { ASYNC_STATUS } from 'libs/constants';
import { isMaterialNumberValid } from 'libs/utils/material-number';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductService } from 'services';

import styles from './SparePartsProductInfo.module.scss';

const SparePartsProductInfo = (props) => {
  const { materialNumber, handleSetIconState } = props;

  const { sessionId, hasSessionBased } = useSessionBased();
  const { t } = useTranslation();

  const [getProductInfoStatus, setGetProductInfoStatus] = useState(
    ASYNC_STATUS.IDLE
  );

  const getBasicProductInfo = useCallback(async () => {
    try {
      if (!!hasSessionBased && !sessionId) return Promise.reject();
      if (!isMaterialNumberValid(materialNumber)) return Promise.reject();

      const disisResponse = await ProductService.getProductSpareParts(
        materialNumber,
        sessionId
      );

      const response = await ProductService.getBasicProductInfo(materialNumber);
      setGetProductInfoStatus(ASYNC_STATUS.SUCCESS);
      return {
        ...response.data,
        disisLink: disisResponse?.data,
      };
    } catch (error) {
      setGetProductInfoStatus(ASYNC_STATUS.ERROR);
      throw error;
    }
  }, [hasSessionBased, sessionId, materialNumber]);

  const { value, status, error } = useAsync(getBasicProductInfo);

  useEffect(() => {
    handleSetIconState(getProductInfoStatus);
  }, [getProductInfoStatus, handleSetIconState]);

  useEffect(() => {
    if (!isMaterialNumberValid(materialNumber) && materialNumber !== '') {
      handleSetIconState(ASYNC_STATUS.IDLE);
    }
  }, [materialNumber, handleSetIconState]);

  if (status === 'pending') {
    return (
      <div className={styles.sparePartsProductInfo}>
        {t('form.placeHolder.loading')}
      </div>
    );
  }

  if (!isMaterialNumberValid(materialNumber) && value !== '') {
    return (
      <div className={clsx([styles.sparePartsProductInfo, styles.error])}>
        {t('error.invalidMaterialNumber')}
      </div>
    );
  }

  if (error) {
    return (
      <div className={clsx([styles.sparePartsProductInfo, styles.error])}>
        {t('cart.productNotAvailable')}
      </div>
    );
  }

  if (!value) {
    return <div />;
  }

  return (
    <div className={styles.sparePartsProductInfo}>
      <div className={styles.productImage}>
        <ImageWithFallBack src={value?.pictureUrl} />
      </div>
      <Form.Item
        className="custom-form-item"
        label="Product Name"
        labelCol={{ span: 24 }}
      >
        <Input disabled value={value?.materialName} />
      </Form.Item>
      <a
        href={value?.disisLink || '/#'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomButton>{t('sparePartsFinder.button.toSpareParts')}</CustomButton>
      </a>
    </div>
  );
};

SparePartsProductInfo.propTypes = {
  materialNumber: PropTypes.string.isRequired,
  handleSetIconState: PropTypes.func,
};

SparePartsProductInfo.defaultProps = {
  handleSetIconState: () => {},
};

export default React.memo(SparePartsProductInfo);
