import { NotificationManager } from 'components/Notification';
import { getSessionCookieKey } from 'contexts/punchout-context';
import environment from 'environments/environment.dev';
import { USER_TYPES } from 'libs/constants/user';
import { clearCookie } from 'libs/utils/cookies';
import { linkGenerator } from 'libs/utils/language';
import { checkHasPropertiesInObject } from 'libs/utils/object';
import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { CognitoError } from 'services/authenticationService';
import { selectHasSessionBasedShoppingCart } from 'store/selectors/userSelector';
import { trackProfileInformation } from 'store/slices/adobeAnalyticSlice';
import { authenticationActions } from 'store/slices/authenticationSlice';
import { getUserRoles, getUserRolesFailed } from 'store/slices/roleSlice';
import { setupActions } from 'store/slices/setupSlice';
import { userActions } from 'store/slices/userSlice';

const {
  aws: {
    cognito: { identityProvider },
  },
} = environment;

function isAuthenticationTokenObject(tokenObject) {
  const hasDecodedToken = checkHasPropertiesInObject(
    tokenObject?.decoded,
    'idToken',
    'accessToken'
  );
  const hasJwtToken = checkHasPropertiesInObject(
    tokenObject?.jwt,
    'idToken',
    'accessToken',
    'refreshToken'
  );

  return [hasDecodedToken, hasJwtToken].every(Boolean);
}

function extractUserInfoFromCognitoTokens(idToken) {
  const didSignInViaIdentityProvider = checkHasPropertiesInObject(
    idToken,
    'identities'
  );

  let userType = USER_TYPES.TECHNICAL_USER;
  let userId = idToken?.sub;
  const email = idToken?.email;

  if (didSignInViaIdentityProvider) {
    userId = idToken['cognito:username']?.split('_')[1];

    const cognitoGroup = idToken['cognito:groups'][0]?.split('_')[2];

    if (cognitoGroup === identityProvider.customers) {
      userType = USER_TYPES.CUSTOMER_USER;
    } else if (cognitoGroup === identityProvider.employees) {
      userType = USER_TYPES.EMPLOYEE_USER;
    }
  }

  const userInfo = {
    userType,
    userId,
    email,
  };

  return userInfo;
}

function* signInSaga({ payload }) {
  const { AuthenticationService, history } = yield getContext('dependencies');

  try {
    const user = yield call(AuthenticationService.signIn, payload);
    const tokens = yield call(AuthenticationService.getTokens);

    if (isAuthenticationTokenObject(tokens)) {
      yield put(getUserRoles());
      yield all([
        put(authenticationActions.setAuthenticated({ tokens })),
        put(
          authenticationActions.setUserInfo({
            userType: USER_TYPES.TECHNICAL_USER,
            userId: user?.attributes?.sub,
            email: user?.attributes?.email,
          })
        ),
      ]);

      history.push(linkGenerator('/my-workspace'));
    } else {
      yield put(authenticationActions.setNotAuthenticated());
    }

    history.push(linkGenerator('/my-workspace'));
  } catch (error) {
    console.error('error signing in', error);
    if (error === CognitoError.INCORRECT_USERNAME_OR_PASSWORD) {
      // The provided username / password is incorrect
      NotificationManager.error({
        message:
          'notification.error.authentication.incorrectUsernameOrPassword',
      });
    }

    yield all([
      put(userActions.getCurrentTechnicalUserInfoFailed()),
      put(getUserRolesFailed()),
    ]);

    history.push(linkGenerator(''));
  }
}

function* signInViaIdentityProviderSaga({ payload }) {
  const { AuthenticationService } = yield getContext('dependencies');

  try {
    yield call(
      AuthenticationService.signInViaIdentityProvider,
      payload.id,
      payload.from
    );
  } catch (error) {
    console.error(error);
  }
}

function* signOutSaga() {
  const { AuthenticationService, history } = yield getContext('dependencies');
  const hasSessionBasedShoppingCart = yield select(
    selectHasSessionBasedShoppingCart
  );

  try {
    yield put(userActions.clearUserDetails());

    if (hasSessionBasedShoppingCart) {
      yield clearCookie(getSessionCookieKey());
    }

    yield call(AuthenticationService.signOut);
    history.push(linkGenerator(''));
  } catch (error) {
    console.error(error);
  }
}

function* checkAuthenticationSaga() {
  const { AuthenticationService, history } = yield getContext('dependencies');

  try {
    const tokens = yield call(AuthenticationService.getTokens);

    if (isAuthenticationTokenObject(tokens)) {
      yield put(getUserRoles());
      const userInfo = extractUserInfoFromCognitoTokens(tokens.decoded.idToken);
      const { userId, userType } = userInfo;

      yield all([
        put(authenticationActions.setAuthenticated({ tokens })),
        put(authenticationActions.setUserInfo(userInfo)),
      ]);

      yield put(
        trackProfileInformation({
          userID: userId,
          userType,
        })
      );
    } else {
      yield put(authenticationActions.setNotAuthenticated());
    }
  } catch (error) {
    console.error(error);

    if (error === CognitoError.REFRESH_TOKEN_EXPIRED) {
      yield call(AuthenticationService.signOut);
    } else {
      yield put(authenticationActions.setNotAuthenticated());
    }

    yield put(getUserRolesFailed());

    NotificationManager.error({
      message: 'notification.error.somethingWentWrong',
    });

    history.push(linkGenerator(''));
  }
}

function* setAuthenticatedSaga() {
  try {
    yield put(setupActions.fetchSetupData());
  } catch (error) {
    console.error('error get setup data:', error);
    yield put(setupActions.fetchSetupDataFailure());
  }
}

function* authenticationSaga() {
  yield takeLatest(
    authenticationActions.checkAuthentication.toString(),
    checkAuthenticationSaga
  );
  yield takeLatest(
    authenticationActions.setAuthenticated.toString(),
    setAuthenticatedSaga
  );
  yield takeLeading(authenticationActions.signIn.toString(), signInSaga);
  yield takeLatest(
    authenticationActions.signInViaIdentityProvider.toString(),
    signInViaIdentityProviderSaga
  );
  yield takeLatest(authenticationActions.signOut.toString(), signOutSaga);
}

export default authenticationSaga;
