export default {
  enableReduxDevTool: true,
  b2bDomain: 'https://stage.business.kaercher.com',
  loginUrl: 'https://login-stage.app.kaercher.com',
  baseUrl: 'https://api-stage.business.kaercher.com',
  shopFAPIUrl: 'https://shop-fapi.api-stage.business.kaercher.com/api',
  adminFAPIUrl: 'https://admin-fapi.api-stage.business.kaercher.com/api',
  serviceFAPIUrl: 'https://service-fapi.api-stage.business.kaercher.com/api',
  aws: {
    cognito: {
      userPoolId: 'eu-central-1_m8hQ3X93h',
      oauthDomain: 'ak-b2bec-stage-users.auth.eu-central-1.amazoncognito.com',
      clientId: 'nmsu6fgcc42642hvsf1hms05p',
      region: 'eu-central-1',
      identityProvider: {
        customers: 'b2b-ec-customer',
        employees: 'b2b-ec-employee',
      },
    },
  },
};
